<template>
    <BT-Blade-Items
        addBladeName="courier-pointer"
        :bladesData="bladesData"
        bladeName="courier-pointers"
        canAdd
        :itemProperties="['ID','IsSelfCourier','CourierAgreement','Weekdays']"
        navigation="courier-pointers"
        showList
        title="Courier Pointers"
        useServerPagination
        :defaultBladeWidth="350">
        <template v-slot:listItem="{ item }">
            <v-list-item-content>
                <v-list-item-title>{{ item.isSelfCourier ? 'Self Courier' : item.courierAgreement.seller.companyName }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.weekdays }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Courier-Pointers-Blade',
    props: {
        bladesData: null
    }
}
</script>